<template>
  <b-container fluid="" class="page-rma">
    <div class="d-flex d-flex justify-content-between">
      <div><h1>{{ $t('Return') }} #<span>{{ rmaNumber }}</span></h1></div>
      <div class="flex-shrink-1"><ImHere v-if="!loading"></ImHere></div>
    </div>
    <div class="mb-3 text-muted font-italic">{{ $t('Created at') }} <b>{{ dateTimeFormat(newRma.createdAt) }}</b> {{ $t('for') }}
      <b-link v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="mb-4" target="_blank" :href="'https://dashboard.plein-lab.com/crm/orders?query_orderid=' + newRma.order.orderNumber">{{ newRma.order.orderNumber }}</b-link><span v-else>{{ newRma.order.orderNumber }}</span></div>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card-group deck>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
          <b-card>
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
        </b-card-group>
        <br class="p-5">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <div v-if="!loading">
        <b-row>
          <b-col>
            <b-card-group deck>
              <b-card header-tag="header" header-class="p-0">
                <template #header>
                  <b-row class="px-3 align-items-center">
                    <b-col><h6 class="py-3 mb-0">{{ $t('Return Information') }}</h6></b-col>
                  </b-row>
                </template>
                <b-table-simple class="mb-0" responsive="true" borderless small>
                  <b-tr><b-th>{{ $t('Duty') }}:</b-th><b-td>{{ newRma.order.duty ? 'DDP' : 'DDU' }}</b-td></b-tr>
                  <b-tr><b-th>{{ $t('LS') }}:</b-th><b-td class="text-break">{{ newRma.order.lsNumbers.join(', ') }}</b-td></b-tr>
                  <b-tr><b-th>{{ $t('Comment') }}:</b-th><b-td class="text-break">{{ newRma.comment }}</b-td></b-tr>
                  <template v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')">
                    <b-tr><b-th>{{ $t('Ordered By') }}:</b-th><b-td class="text-break">{{ newRma.order.createdBy }}</b-td></b-tr>
                    <b-tr><b-td colspan="2"><hr></b-td></b-tr>
                    <b-tr><b-th>{{ $t('To Return') }}:</b-th><b-td>{{ newRma.order.currency }} {{ newRma.amountToReturn.toFixed(2) }}</b-td></b-tr>
                    <b-tr><b-th class="pl-3">{{ $t('Approved') }}:</b-th><b-td>{{ newRma.order.currency }} {{ newRma.approvedAmount.toFixed(2) }}</b-td></b-tr>
                    <b-tr><b-th class="pl-3">{{ $t('Returned') }}:</b-th><b-td>{{ newRma.order.currency }} {{ newRma.paidAmount.toFixed(2) }}</b-td></b-tr>
                    <b-tr><b-td class="pl-5">{{ $t('Vouchered') }}:</b-td><b-td>{{ newRma.order.currency }} {{ newRma.createdStoreCreditAmount.toFixed(2) }}</b-td></b-tr>
                    <b-tr><b-td class="pl-5">{{ $t('Refunded') }}:</b-td><b-td>{{ newRma.order.currency }} {{ newRma.refunded.toFixed(2) }}</b-td></b-tr>
                  </template>
                </b-table-simple>
              </b-card>
              <b-card v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" header-tag="header" header-class="p-0" :border-variant="notFilledPaymentInfo ? 'danger' : ''">
                <template #header>
                  <b-row class="px-3 align-items-center">
                    <b-col><h6 class="py-3 mb-0">{{ $t('Payment Information') }}</h6></b-col>
                    <b-col v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="flex-grow-0"><b-button variant="link" size="sm" @click="editPaymentInfo = !editPaymentInfo">{{ $t(editPaymentInfo ? 'Cancel' : 'Edit') }}</b-button></b-col>
                  </b-row>
                </template>
                <b-table-simple class="mb-0" responsive="true" borderless small>
                  <b-tr><b-td class="text-right">{{ $t('Items Amount') }}:</b-td><b-td>{{ newRma.order.currency }} {{ newRma.order.paymentInfo.itemsAmount.toFixed(2) }}</b-td></b-tr>
                  <b-tr><b-td class="text-right">{{ $t('Shipping') }}:</b-td><b-td>{{ newRma.order.currency }} {{ newRma.order.paymentInfo.totalShippingPrice.toFixed(2) }}</b-td></b-tr>
                  <template v-if="newRma.order.paymentInfo.isCod">
                    <b-tr><b-td class="text-right"></b-td><b-td>{{ $t('Price') }}: {{ newRma.order.currency }} {{ newRma.order.paymentInfo.shippingPrice.toFixed(2) }}</b-td></b-tr>
                    <b-tr><b-td></b-td><b-td>COD Fee: {{ newRma.order.currency }} {{ newRma.order.paymentInfo.codFee.toFixed(2) }}</b-td></b-tr>
                  </template>
                  <b-tr v-if="newRma.region === '02'"><b-td class="text-right">{{ $t('Sales Tax') }}:</b-td><b-td>{{ newRma.order.currency }} {{ newRma.order.paymentInfo.salesTax.toFixed(2) }}</b-td></b-tr>
                  <b-tr><b-th class="text-right">{{ $t('Subtotal') }}:</b-th><b-td><b>{{ newRma.order.currency }} {{ newRma.order.paymentInfo.subTotalAmount.toFixed(2) }}</b></b-td></b-tr>
                  <b-tr><b-th class="text-right">{{ $t('Total Amount') }}:</b-th><b-td><b>{{ newRma.order.currency }} {{ newRma.order.paymentInfo.totalPaidAmount.toFixed(2) }}</b></b-td></b-tr>
                  <b-tr><b-td colspan="2" class="text-center">-- {{ $t('Payment') }} --</b-td></b-tr>
                  <b-tbody v-for="payment in newRma.order.paymentInfo.methods" :key="payment.paymentId">
                    <b-tr><b-th class="text-right">{{ $t('Method') }}:</b-th><b-td>{{ $t(`payment_methods.${payment.method}`) }}</b-td></b-tr>
                    <b-tr v-if="payment.system"><b-td class="text-right">{{ $t('System') }}:</b-td><b-td>{{ payment.system }}</b-td></b-tr>
                    <b-tr v-if="payment.reference"><b-td class="text-right">{{ $t('Reference') }}:</b-td><b-td><b-link :href="getPaymentSystemLink(payment)" target="_blank">{{ payment.reference }}</b-link></b-td></b-tr>
                    <b-tr><b-td class="text-right">{{ $t('Paid') }}:</b-td><b-td><b>{{ newRma.order.currency }} {{ payment.amount.toFixed(2) }}</b></b-td></b-tr>
                  </b-tbody>
                  <template v-if="usedStoreCredit">
                    <b-tr><b-th class="text-right">{{ $t('Method') }}:</b-th><b-td>{{ $t('Store Credit') }}</b-td></b-tr>
                    <b-tr><b-td class="text-right">{{ $t('Code') }}:</b-td><b-td>{{ usedStoreCredit.coupon }}</b-td></b-tr>
                    <b-tr><b-td class="text-right">{{ $t('Amount') }}:</b-td><b-td><b>{{ newRma.order.currency }} {{ Math.abs(usedStoreCredit.order.reduce((sum, promotion) => (sum + promotion.price), 0)).toFixed(2) }}</b></b-td></b-tr>
                  </template>
                  <b-tr v-if="getCoupons.length > 0">
                    <b-th class="align-text-top text-right">{{ $t('Coupons') }}:</b-th>
                    <b-td>
                      <div v-for="(coupon, i) in getCoupons" :key="coupon.coupon">
                        <b :id="'popover-target-' + i" class="text-break">{{ coupon.coupon }}</b>
                        <span v-if="getCoupons.length > 1 && i < getCoupons.length - 1">, </span>
                        <b-popover :target="'popover-target-' + i" triggers="hover" placement="top">
                          <template #title>{{ coupon.coupon }}</template>
                          <div v-for="type in ['order', 'shipping']" :key="type">
                            <div v-if="coupon[type] && coupon[type].length > 0">
                              <i>{{ type }}:</i>
                              <div class="pl-3" v-for="promotion in coupon[type]" :key="promotion.promotion_id">
                                {{ promotion.type }}: {{ promotion.amount }}<br>
                                {{ $t('Discount') }}: <b>{{ promotion.price.toFixed(2) }}</b><br>
                                {{ promotion.promotion_id }}
                              </div>
                            </div>
                          </div>
                        </b-popover>
                      </div>
                    </b-td>
                  </b-tr>
                </b-table-simple>
                <RmaPaymentBankAccount
                    :edit="editPaymentInfo"
                    :action-api-url="getActionUrl.updatePaymentInfo"
                    :button-variant="getButtonColor"
                    v-model="newRma.paymentBankAccount"
                ></RmaPaymentBankAccount>
              </b-card>
              <b-card header-tag="header" header-class="p-0" >
                <template #header>
                  <b-row class="px-3 align-items-center">
                    <b-col><h6 class="py-3 mb-0">{{ $t('Shipping Address') }}</h6></b-col>
                    <b-col v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="flex-grow-0"><b-button @click="editShippingAddress = !editShippingAddress" variant="link" size="sm">{{ $t(editShippingAddress ? 'Cancel' : 'Edit') }}</b-button></b-col>
                  </b-row>
                </template>
                <RmaShippingAddress
                    :edit="editShippingAddress"
                    :action-api-url="getActionUrl.updateShippingAddress"
                    :button-variant="getButtonColor"
                    :region="newRma.region"
                    v-model="newRma.shippingAddress"
                    :email="newRma.order.email"
                ></RmaShippingAddress>
              </b-card>
              <b-card header-tag="header" header-class="p-0" :border-variant="(getAuthorizedItems.length > 0 && !newRma.shippingStatus.carrier) ? 'danger' : ''">
                <template #header>
                  <b-row class="px-3 align-items-center">
                    <b-col><h6 class="py-3 mb-0">{{ $t('Shipping Status') }}</h6></b-col>
                    <b-col v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="flex-grow-0"><b-button variant="link" size="sm" @click="editShippingStatus = !editShippingStatus">{{ $t(editShippingStatus ? 'Cancel' : 'Edit') }}</b-button></b-col>
                  </b-row>
                </template>
                <RmaShippingStatus
                    :edit="editShippingStatus"
                    :action-api-url="getActionUrl.updateShippingStatus"
                    :button-variant="getButtonColor"
                    v-model="newRma.shippingStatus"
                ></RmaShippingStatus>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
        <form class="my-5" method="post" :action="getActionUrl.updateRmaProducts" @submit.prevent="updateRmaItems($event)">
          <b-overlay :show="rmaItemsIsSending">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th class="col-checkbox"><b-form-checkbox v-model="selectedAll" @change="selectAll"></b-form-checkbox></b-th>
                  <b-th class="col-preview" if="userHasRole('ROLE_WAREHOUSE')">{{ $t('Photo') }}</b-th>
                  <b-th class="col-sku">{{ $t('SKU') }}</b-th>
                  <b-th v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-price">{{ $t('Price') }}</b-th>
                  <b-th class="col-reason">{{ $t('Reason') }}</b-th>
                  <b-th v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-resolution">{{ $t('Resolution') }}</b-th>
                  <b-th class="col-action-exchange">{{ $t('Exchange') }}</b-th>
                  <b-th v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-refund">{{ $t('Refund') }}</b-th>
                  <b-th v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-voucher">{{ $t('Voucher') }}</b-th>
                  <b-th class="col-condition">{{ $t('Condition') }}</b-th>
                  <b-th class="col-operation">{{ $t('Operation') }}</b-th>
                  <b-th class="col-status">{{ $t('Status') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <RmaProduct
                    v-for="(item, index) in newRma.products"
                    v-model="newRma.products[index]"
                    :key="item.productNumber"
                    :rma-id="newRma.id"
                    :rma-number="rmaNumber"
                    :currency="newRma.order.currency"
                    :region="newRma.region"
                    :country="newRma.shippingAddress.countryCode"
                    :selected-all="selectedAll"
                    v-on:selectProduct="selectProduct"
                ></RmaProduct>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td class="col-checkbox"><b-form-checkbox v-model="selectedAll" @change="selectAll"></b-form-checkbox></b-td>
                  <b-th class="col-preview" if="userHasRole('ROLE_WAREHOUSE')"></b-th>
                  <b-td class="col-sku"></b-td>
                  <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-price"></b-td>
                  <b-td class="col-reason">
                    <b-form-select v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" v-model="selectedItemsField.reason" :options="returnReasons" :disabled="!itemsAreSelected"></b-form-select>
                  </b-td>
                  <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-resolution">
                    <b-form-select
                        v-model="selectedItemsField.resolution"
                        :options="resolutions"
                        :disabled="!itemsAreSelected"
                        :size="selectedItemsField.resolution === 'EXCHANGE' ? 'sm' : ''"
                    ></b-form-select>
                    <b-input-group v-if="selectedItemsField.resolution === 'EXCHANGE'" size="sm">
                      <b-form-input size="sm" type="text" v-model="selectedItemsField.newSize" :disabled="!itemsAreSelected"></b-form-input>
                      <b-form-input size="sm" type="text" v-model="selectedItemsField.newColor" :disabled="!itemsAreSelected"></b-form-input>
                    </b-input-group>
                  </b-td>
                  <b-td class="col-action-exchange">
                    <b-input v-model="selectedItemsField.reservationNumber" :disabled="!itemsAreSelected" :placeholder="$t('Reservation')" size="sm"></b-input>
                    <b-input-group size="sm">
                      <b-select v-model="selectedItemsField.reservationWarehouse" :disabled="!itemsAreSelected" :options="dictionaries.warehouses"></b-select>
                      <b-select v-model="selectedItemsField.reservationStatus" :disabled="!itemsAreSelected" :options="reservationStatuses"></b-select>
                    </b-input-group>
                  </b-td>
                  <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-refund">
                    <b-input-group size="sm" :prepend="newRma.order.currency" class="flex-nowrap">
                      <b-input number type="number" step=".01" min="0.00" v-model.number="selectedItemsField.approvedRefundAmount" :placeholder="$t('Approved Amount')" :disabled="!itemsAreSelected"></b-input>
                    </b-input-group>
                    <b-input-group>
                        <b-input v-model="selectedItemsField.refundReference" :placeholder="$t('Refund Reference')" size="sm" :disabled="!itemsAreSelected"></b-input>
                    </b-input-group>
                  </b-td>
                  <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-voucher">
                    <b-input-group size="sm" class="text-nowrap">
                      <b-input-group-prepend is-text>
                        <b-form-checkbox
                            switch
                            v-model="selectedItemsField.voucherStatus"
                            class="mb-n1"
                            value="ENABLED"
                            unchecked-value="DISABLED"
                            :disabled="!itemsAreSelected"
                        ></b-form-checkbox>
                      </b-input-group-prepend>
                      <b-input
                          v-model="selectedItemsField.voucherNumber"
                          :placeholder="$t('Voucher')"
                          :disabled="!itemsAreSelected"
                      ></b-input>
                    </b-input-group>
                    <b-input-group size="sm" :prepend="newRma.order.currency">
                      <b-input number
                               type="number"
                               step=".01"
                               min="0.00"
                               v-model.number="selectedItemsField.voucherAmount"
                               :placeholder="$t('Amount')"
                               :disabled="!itemsAreSelected"
                      ></b-input>
                    </b-input-group>
                  </b-td>
                  <b-td class="col-condition">
                    <b-form-select v-model="selectedItemsField.condition" :options="conditions" :disabled="!itemsAreSelected"></b-form-select>
                  </b-td>
                  <b-td class="col-operation">
                    <b-form-select v-model="selectedItemsField.operation" :options="operations" :disabled="!itemsAreSelected"></b-form-select>
                  </b-td>
                  <b-td class="col-status">
                    <b-form-select v-model="selectedItemsField.status" :options="rmaStatuses" :disabled="!itemsAreSelected"></b-form-select>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
            <b-row align-h="between">
              <b-col>
                <b-button v-if="userHasRole('ROLE_CAN_CREATE_INTEX_CREDIT_NOTE')"
                           type="button" v-b-modal.confirm-store-credit
                           :variant="getButtonColor"
                           :disabled="!itemsAreSelected || rmaCreditNoteIsSending"
                >
                  <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !rmaCreditNoteIsSending}" label="Small Spinner"></b-spinner>
                  <span :class="{'d-none': rmaCreditNoteIsSending}">{{ $t('Create Intex Credit Note') }}</span>
                </b-button><br>
                <b-button v-if="userHasRole('ROLE_CAN_SEND_RMA_TO_SPEDIMEX')"
                          type="button"
                          @click="$bvModal.show('confirm-send-to-spedimex');"
                          :variant="getButtonColor"
                          :disabled="!itemsAreSelected || rmaToSpedimexSending"
                >
                  <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !rmaToSpedimexSending}" label="Small Spinner"></b-spinner>
                  <span :class="{'d-none': rmaToSpedimexSending}">{{ $t('Send to Spedimex') }}</span>
                </b-button><br>
                <b-button v-if="userHasRole('ROLE_CAN_PAY_REFUND')"
                          type="button"
                          @click="$bvModal.show('confirm-refund-adyen');"
                          :variant="getButtonColor"
                          :disabled="!itemsAreSelected || refundAdyenProcessing"
                >
                  <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !refundAdyenProcessing}" label="Small Spinner"></b-spinner>
                  <span :class="{'d-none': refundAdyenProcessing}">{{ $t('Adyen Refund') }}</span>
                </b-button>
              </b-col>
              <b-col class="flex-grow-0" align-self="end" >
                <b-button type="submit" :variant="getButtonColor" :disabled="rmaItemsIsSending">
                  <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !rmaItemsIsSending}" label="Small Spinner"></b-spinner>
                  <span :class="{'d-none': rmaItemsIsSending}">{{ $t('Save') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </form>
        <b-modal
            id="confirm-store-credit"
            :title="$t('Confirm creating Credit Notes for next products')"
            size="lg"
            @ok="sendCreditNote"
        >
          <template #modal-ok>
            <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !rmaCreditNoteIsSending}"></b-spinner>
            <span :class="{'d-none': rmaCreditNoteIsSending}">{{ $t('Send') }}</span>
          </template>
          <template #modal-cancel>{{ $t('Close') }}</template>
          <b-table-simple small responsive>
            <b-tr>
              <b-th>{{ $t('Sent')}}</b-th>
              <b-th>{{ $t('Sku')}} ({{ $t('Size')}})</b-th>
              <b-th>{{ $t('Resolution')}}</b-th>
              <b-th>{{ $t('Approved')}}</b-th>
              <b-th>{{ $t('Status')}}</b-th>
            </b-tr>
            <b-tr v-for="product in newRma.products.filter(el => this.selected.includes(el.id))" :key="product.id" :variant="product.intexReturnPos ? 'success' : ''">
              <b-td>{{ product.intexReturnPos ? product.intexReturnPos.rmsIdent : '' }}</b-td>
              <b-td>{{ product.sku }} ({{ product.size }}) #{{ product.productNumber }}</b-td>
              <b-td>{{ product.resolution }}</b-td>
              <b-td>{{ product.approvedRefundAmount }}</b-td>
              <b-td>{{ product.status }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-modal>
        <SendRmaToSpedimexModal
            v-model="newRma.products"
            :selected="selected"
            :rma-number="rmaNumber"
        ></SendRmaToSpedimexModal>
        <RefundAdyenModal
            v-model="newRma.products"
            :selected="selected"
            :rma-number="rmaNumber"
            :currency="newRma.order.currency"
        ></RefundAdyenModal>
        <section class="mt-5">
          <h2>{{ $t('RMA Comments') }}</h2>
          <RmaComments :rma-id="rma.id"></RmaComments>
        </section>
        <template v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')">
          <section class="mt-5">
            <h2>{{ $t('RMA History') }}</h2>
            <RmaHistory :rma-id="rma.rmaNumber"></RmaHistory>
          </section>
        </template>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>
<script>
import RmaProduct from "./RmaProduct";
import RmaComments from './RmaComments'
import RmaHistory from './RmaHistory'
import ImHere from "./ImHere"
import 'viewerjs/dist/viewer.css'

import {getJsonData, postJsonData} from "../services/fetchApi";
import RmaPaymentBankAccount from './RmaPaymentBankAccount';
import RmaShippingAddress from "./RmaShippingAddress";
import RmaShippingStatus from "./RmaShippingStatus";
import UserCanMixin from "../mixins/UserCanMixin";
import SendRmaToSpedimexModal from "./SendRmaToSpedimexModal";
import RefundAdyenModal from "./RefundAdyenModal";

import {ReturnReason as ReturnReasonEnum} from "../enum/ReturnReason";
import {Resolution as ResolutionEnum} from "../enum/Resolution";
import {ReservationStatus as ReservationStatusEnum} from "../enum/ReservationStatus";
import {Condition as ConditionEnum} from "../enum/Condition";
import {Operation as OperationEnum} from "../enum/Operation";
import {RmaStatus as RmaStatusEnum} from "../enum/RmaStatus";

export default {
  name: 'Rma',
  mixins: [UserCanMixin],
  data() {
    return {
      editPaymentInfo: false,
      editShippingAddress: false,
      editShippingStatus: false,
      rmaItemsIsSending: false,
      rmaCreditNoteIsSending: false,
      rmaToSpedimexSending: false,
      refundAdyenProcessing: false,

      loading: true,
      rmaNumber: null,
      rma: {
        salutation: null
      },
      newRma: {
        order: {
          orderNumber: null
        }
      },
      selected: [],
      selectedAll: false,
      model: {
        photos: {},
      },
      selectedItemsField: {
        newSize: null,
        newColor: null,
        reason: null,
        reservation: null,
        condition: null,
        operation: null,
        status: null,
        reservationNumber: null,
        reservationStatus: null,
        reservationWarehouse: null,
        voucherNumber: null,
        voucherAmount: null,
        voucherStatus: null,
        approvedRefundAmount: null,
        refundReference: null,
      },
    }
  },
  created() {
    this.rmaNumber = this.$route.params.rmaNumber
  },
  methods: {
    initModel() {
      const $this = this
      this.newRma.products.forEach(item => {
        $this.model.photos[item.id] = null;
      });
    },
    updateRmaItems(e) {
      const $this = this;
      let nameItemFields = ['id', 'reasonOfReturn', 'resolution', 'newSize', 'newColor', 'reservationNumber', 'reservationWarehouse', 'reservationStatus', 'voucherNumber',
        'voucherStatus', 'voucherAmount', 'approvedRefundAmount', 'refundReference', 'condition', 'operation', 'status', 'isFranchise'];
      let valuesFields = $this.newRma.products.map(item => {
        return nameItemFields.reduce((model, field) => {
          model[field] = item[field];
          return model;
        }, {});
      });
      this.rmaItemsIsSending = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(
          e.target.action,
          JSON.stringify({returnProducts: valuesFields}),
      ).then(json => {
        Object.keys($this.selectedItemsField).forEach(field => {
          $this.selectedItemsField[field] = null;
        });

        $this.newRma.products = json.rma.products;

        $this.$store.dispatch('getRmaHistory');

        $this.$store.commit('showToast', {
          message: $this.$t('RMA items have been successfully updated'),
          title: $this.$t('Updating RMA items'),
          type: 'success'
        });
      }).catch(error => {
        $this.$store.commit('showToast', {
          message: $this.$t('RMA items have not been updated') + ':' + (error ?? $this.$t('Unknown error')),
          title: $this.$t('Updating RMA items'),
          type: 'danger'
        });
      })
      .finally(() => {
        $this.rmaItemsIsSending = false;
        $this.$store.commit('setLoadingAnything', false);
      });
      return false;
    },
    sendCreditNote(e) {
      e.preventDefault();
      const $this = this;
      if ($this.rmaCreditNoteIsSending) {
        return false;
      }
      $this.rmaCreditNoteIsSending = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(
          $this.getActionUrl.createCreditNote,
          JSON.stringify({productsId: $this.selected}),
      )
        .then(json => {
          if (json.data) {
            json.data.pos.map(item => {
              $this.newRma.products.filter(el => el.id === item.rmaProductId).forEach(el => {
                el.intexReturnPos = item;
              });
            })
          }
          $this.$store.commit('showToast', {
            message: $this.$t('Credit notes have been sent'),
            title: $this.$t('Credit Note'),
            type: 'success'
          });
        })
        .catch(error => {
          $this.$store.commit('showToast', {
            message: $this.$t('Can not create Credit Note') + ':' + (error ?? $this.$t('Unknown error')),
            title: $this.$t('Credit Note'),
            type: 'danger'
          });
        })
        .finally(() => {
          $this.rmaCreditNoteIsSending = false;
          $this.$store.commit('setLoadingAnything', false);
        });
      return false;
    },
    setSelectedItemsValue(field, value) {
      if (!this.rmaItemsIsSending) {
        this.newRma.products.filter(el => this.selected.includes(el.id)).forEach(el => {
          el[field] = value;
        });
      }
    },
    selectAll() {
      const $this = this;
      if (!$this.selectedAll) {
        $this.$set(this, 'selected', []);
      } else {
        $this.$set(this, 'selected', $this.newRma.products.map(product => product.id));
      }
    },
    selectTd(e, itemId) {
      if ('TD' === e.target.tagName) {
        this.selectProduct(itemId);
      }
    },
    selectProduct(rmaProductId) {
      const index = this.selected.indexOf(rmaProductId);
      if (index < 0) {
        this.selected.push(rmaProductId);
      } else {
        this.selected.splice(index, 1);
      }
    },
    dateTimeFormat: function (datetime) {
      if (!datetime) {
        return '';
      }
      return (new Date(datetime)).toLocaleString();
    },
    getPaymentSystemLink(payment) {
      if (payment.system === 'ADYEN') {
        return 'https://ca-live.adyen.com/ca/ca/accounts/showTx.shtml?txType=Payment&pspReference=' + payment.reference;
      } else if (payment.system === 'SBER') {
        return 'https://securepayments.sberbank.ru/mportal3/admin/orders/' + payment.reference;
      }

      return '';
    },
    uploadedFileUrl(path) {
      return process.env.VUE_APP_BACKEND_SERVER + path;
    },
    dashboardProductUrl(path) {
      return 'https://dashboard.plein-lab.com/catalog?query_sku=' + path + '&form_type_get=get&query_env=staging';
    }
  },
  mounted() {
    const $this = this;
    getJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + $this.rmaNumber, 'get', '')
      .then(json => {
        $this.$set($this, 'newRma', json.rma);
        $this.initModel();
        $this.$store.commit('setRma', json.rma);
        $this.loading = false;
      })
      .catch(function(error) {
        $this.$bvToast.toast(error, {
          title: $this.$t('Error'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true,
          toaster: 'b-toaster-bottom-right'
        });
      });
  },
  computed: {
    getActionUrl: function() {
      return {
        updateRmaProducts: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/updateRmaProducts',
        updateShippingStatus: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/updateShippingStatus',
        updateShippingAddress: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/updateShippingAddress',
        updatePaymentInfo: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/updatePaymentInfo',
        uploadWarehousePhotos: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/uploadWarehousePhotos',
        createCreditNote: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/creditNote',
        sendToSpedimex: process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/sendToSpedimex',
      }
    },
    itemsAreSelected: function () {
      return this.selected.length > 0
    },
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
    getAuthorizedItems() {
      let $this = this;
      return $this.newRma.products.filter(rmaItem => {
        return (rmaItem.status === 'AUTHORIZED');
      });
    },
    isPaymentInfoRequired() {
      return this.newRma.order.paymentInfo.methods.filter(method => method.method === 'CASH_ON_DELIVERY' || method.method === 'CRYPTO_CREDIT').length > 0
          && this.newRma.products.filter(rmaItem => rmaItem.resolution === 'REFUND').length > 0;
    },
    notFilledPaymentInfo() {
      return this.isPaymentInfoRequired
              && ((!this.newRma.paymentBankAccount.bic || this.newRma.paymentBankAccount.bic.length === 0)
                  || (!this.newRma.paymentBankAccount.iban || this.newRma.paymentBankAccount.iban.length === 0)
                  || (!this.newRma.paymentBankAccount.bankName || this.newRma.paymentBankAccount.bankName.length === 0)
                  || (!this.newRma.paymentBankAccount.fullName || this.newRma.paymentBankAccount.fullName.length === 0));
    },
    getUsers() {
      return this.$store.getters["imHere/getUsers"];
    },
    getUsername() {
      return this.$store.getters.getUserName;
    },
    isHereSomebodyElse() {
      return !!(this.getUsers.length > 0
          && (
              (this.getUsers.includes(this.getUsername) && this.getUsers.length > 1)
              || !this.getUsers.includes(this.getUsername)
          ));
    },
    getButtonColor() {
      return this.isHereSomebodyElse ? 'outline-warning' : 'outline-success';
    },
    getCoupons() {
      return this.newRma.order.paymentInfo.coupons ?? [];
    },
    getOrderCoupons() {
      return this.getCoupons.filter(item => item.order);
    },
    usedStoreCredit() {
      return this.newRma.order.paymentInfo.usedStoreCredit;
    },
    returnReasons() {
      const $this = this;
      return Object.keys(ReturnReasonEnum).map(key => ({value: key, text: $this.$t(`return_reasons.${key}`), disabled: key === ReturnReasonEnum.OTHER}));
    },
    resolutions() {
      const $this = this;
      return Object.keys(ResolutionEnum).map(key => ({value: key, text: $this.$t(`resolutions.${key}`)}));
    },
    reservationStatuses() {
      const $this = this;
      return Object.keys(ReservationStatusEnum).map(key => ({value: key, text: $this.$t(`reservation_statuses.${key}`)}));
    },
    conditions() {
      const $this = this;
      return Object.keys(ConditionEnum).map(key => ({value: key, text: $this.$t(`conditions.${key}`)}));
    },
    operations() {
      const $this = this;
      return Object.keys(OperationEnum).map(key => ({value: key, text: $this.$t(`operations.${key}`)}));
    },
    rmaStatuses() {
      const $this = this;
      return Object.keys(RmaStatusEnum).map(key => ({value: key, text: $this.$t(`rma_statuses.${key}`)}));
    }
  },
  watch: {
    // selected(newVal) {
    //   console.log(newVal);
    // },
    'selectedItemsField.reason': function(newVal) {this.setSelectedItemsValue('reasonOfReturn', newVal)},
    'selectedItemsField.resolution': function(newVal) {this.setSelectedItemsValue('resolution', newVal)},
    'selectedItemsField.newSize': function(newVal) {this.setSelectedItemsValue('newSize', newVal)},
    'selectedItemsField.newColor': function(newVal) {this.setSelectedItemsValue('newColor', newVal)},
    'selectedItemsField.reservationNumber': function(newVal) {this.setSelectedItemsValue('reservationNumber', newVal)},
    'selectedItemsField.reservationStatus': function(newVal) {this.setSelectedItemsValue('reservationStatus', newVal)},
    'selectedItemsField.reservationWarehouse': function(newVal) {this.setSelectedItemsValue('reservationWarehouse', newVal)},
    'selectedItemsField.voucherNumber': function(newVal) {this.setSelectedItemsValue('voucherNumber', newVal)},
    'selectedItemsField.voucherAmount': function(newVal) {this.setSelectedItemsValue('voucherAmount', newVal)},
    'selectedItemsField.voucherStatus': function(newVal) {this.setSelectedItemsValue('voucherStatus', newVal)},
    'selectedItemsField.approvedRefundAmount': function(newVal) {this.setSelectedItemsValue('approvedRefundAmount', newVal)},
    'selectedItemsField.refundReference': function(newVal) {this.setSelectedItemsValue('refundReference', newVal)},
    'selectedItemsField.condition': function(newVal) {this.setSelectedItemsValue('condition', newVal)},
    'selectedItemsField.operation': function(newVal) {this.setSelectedItemsValue('operation', newVal)},
    'selectedItemsField.status': function(newVal) {this.setSelectedItemsValue('status', newVal)},
    // 'model': {
    //   handler: function(newVal, oldVal) {
    //     console.log(oldVal, newVal);
    //   },
    //   deep: true
    // }
  },
  components: {
    RmaShippingStatus,
    RmaShippingAddress,
    RmaProduct,
    RmaComments,
    RmaHistory,
    ImHere,
    RmaPaymentBankAccount,
    SendRmaToSpedimexModal,
    RefundAdyenModal
  }
}
</script>
<style>
.input-group > .salutation-select {
  flex-basis: 70px;
  flex-grow: 0;
}

.col-checkbox {
  width: 25px;
}
.col-reason {
  min-width: 200px;
}
.col-resolution {
  min-width: 140px;
}
.col-condition {
  min-width: 220px;
}
.col-status {
  min-width: 150px;
}
.col-reset {
  width: 100px;
}
.col-operation {
  min-width: 100px;
}
.col-price {
  /*min-width: 200px;*/
}
.col-action-exchange, .col-action-refund, .col-action-voucher {
  min-width: 180px;
}
.col-action .input-group-text {
  width: 50px;
  padding-left: 8px;
}

.page-rma table td,
.page-rma table th {
  vertical-align: middle;
}
</style>