const Operation = Object.freeze({
    EXCHANGED: 'EXCHANGED',
    LABEL_SENT: 'LABEL_SENT',
    PICK_UP_ORGANIZED: 'PICK_UP_ORGANIZED',
    REFUNDED: 'REFUNDED',
    REPAIRED_BACK_IN_STOCK_001: 'REPAIRED_BACK_IN_STOCK_001',
    RETURN_AUTHORIZED: 'RETURN_AUTHORIZED',
    RETURN_CLOSED: 'RETURN_CLOSED',
    SECOND_CHOICE_999: 'SECOND_CHOICE_999',
    SEND_BACK_005: 'SEND_BACK_005',
    SEND_BACK_REPAIRED_005: 'SEND_BACK_REPAIRED_005',
    SUPPLIER: 'SUPPLIER',
    THIRD_CHOICE_350: 'THIRD_CHOICE_350',
    TO_BE_REFUNDED: 'TO_BE_REFUNDED',
    VOUCHER: 'VOUCHER',
    IN_001: 'IN_001',
    IN_005_TO_BE_SENT_TO_THE_CUSTOMER: 'IN_005_TO_BE_SENT_TO_THE_CUSTOMER',
    IN_350: 'IN_350',
    IN_999: 'IN_999',
    ITEM_DID_NOT_ARRIVE: 'ITEM_DID_NOT_ARRIVE',
    ITEM_NOT_REPAIRABLE: 'ITEM_NOT_REPAIRABLE',
    ITEM_OK_IN_001: 'ITEM_OK_IN_001',
    ITEM_WORN: 'ITEM_WORN',
    REPAIRABLE: 'REPAIRABLE',
    WRONG_ITEM_ARRIVED: 'WRONG_ITEM_ARRIVED',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
    CN_CREATED: 'CN_CREATED',
    CN_PROCESSED: 'CN_PROCESSED',
    CN_FAILURE: 'CN_FAILURE',
    ADYEN_REFUND_FAILED: 'ADYEN_REFUND_FAILED'
});

export  {Operation}